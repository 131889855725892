.home{
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.initial-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: white;
    gap: 50px;
    width: 80%;
}

.line{
    position: relative;
    opacity: 0;
}

.line-title{
    font-size: 38px;
    font-weight: 600;
}

.line-text{
    margin-top: 10px;
    font-size: 20px;
    letter-spacing: 1px;
}

@keyframes fade {
    0%{
        opacity: 0;
        top: 40px;
    }
    100%{
        opacity: 1;
        top: 0;
    }
}
@keyframes fadeRev {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


.resr-display{
    position: absolute;
    top: 50%;
    left: 120px;
    transform: translateY(-50%);
    display: none;
    flex-direction: column;
    gap: 30px;
    color: white;
    padding: 50px;
    padding-left: 80px;
    border-left: 2px solid rgb(191, 191, 191);
}

.resr-title{
    font-size: 50px;
}

.resr-subtitle{
    font-size: 20px;
}

.resr-btn{
    width: 220px;
    padding: 10px 20px;
    color: black;
    font-weight: 600;
    font-size: 16px;
    background-color: #FFB001;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}


@keyframes fadeSide {
    0%{
        opacity: 0;
        left: 50px;
    }
    100%{
        opacity: 1;
        left: 120px;
    }
}

@keyframes fadeSidePhone {
    0%{
        opacity: 0;
        top: 50%;
        left: 40%;
        transform: translate(-50%,-50%);
        padding: 0;
        margin-left: 0px;
    }
    100%{
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 0;
        margin-left: 0px;
    }
}

@keyframes fadeSidePhone2 {
    0%{
        opacity: 0;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        padding: 0;
        margin-left: 0px;
    }
    100%{
        margin-left: 0px;
        padding: 0;
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes fadeSideOut {
    0%{
        opacity: 1;
        left: 120px;
    }
    90%{
        left: 200px;
        opacity: 0;
    }
    100%{
        opacity: 0;
        left: 50px;
    }
}

@keyframes fadeSideOutPhone {
    0%{
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 0;
        margin-left: 0px;
    }
    100%{
        top: 50%;
        left: 60%;
        transform: translate(-50%,-50%);
        padding: 0;
        margin-left: 0px;
        opacity: 0;
    
    }
}


@keyframes fadeSideOutPhone2 {
    0%{
        margin-left: 0px;
        padding: 0;
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    90%{
        margin-left: 0px;
        padding: 0;
        opacity: 0;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);
    }
    100%{
        opacity: 0;
        top: 40%;
        left: 60%;
        transform: translate(-50%, -50%);
    }
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    .initial-content{
       top: 40%;
       gap: 100px;
       padding: 20px;
    }

    .line-title{
        font-size: 35px;
    }

    .line-text{
        font-size: 25px;
        margin-top: 10px;
    }
    .resr-display{
        gap: 60px;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){

}

/* iPad portrait*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){
    .initial-content{
        top: 40%;
        gap: 80px;
        padding: 20px;
     }

     .line-text{
        margin-top: 10px;
     }
}


@media (max-width:767px){
    .resr-display{
        gap: 80px;
        justify-content: center;
        align-items: center;
        border-left: none;
        gap: 40px;
    }
    .resr-title{
        font-size: 35px;
        text-align: center;
    }

    .resr-subtitle{
        font-size: 20px;
        text-align: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
    
    .initial-content{
         text-align: center;
    }
    
    .line-title{
        font-size: 30px;
    }
    
    .line-text{
        font-size: 18px;
        margin-top: 10px;
    }

    .resr-display{
        align-items: center;
        justify-content: center;
        width: 70%;
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){

    .initial-content{
        gap: 20px;
        justify-content: flex-start;
        margin-top: 30px;
    }
    .line{
        top: 0px;
    }
    .line-title{
        font-size: 12px;
    }
    .line-text{
        margin-top: 0;
        font-size: 10px;
    }
    .resr-display{
        gap: 60px;
        border-left: none;
        gap: 30px;
        margin-top: 30px;
        width: auto;
        margin-left: -15px;
        align-items: center;
        justify-content: center;
    }
    .resr-title{
        font-size: 30px;
        text-align: center;
        width: 100%;
    }

    .resr-subtitle{
        font-size: 18px;
        text-align: center;
    }
    
}

