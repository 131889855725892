:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.career{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career-content{
    /* margin-top: 200px; */
    margin-top: 230px;
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.career-title{
    color: white;
    font-size: var(--title-font);
}

.career-descr{
    display: grid;
    gap: 30px;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
}

.career-item-container{  
    align-self: flex-start;
    background-color: white;
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.career-item{
    /* font-size: 20px; */
    font-size: var();
    position: relative;
    background: none;
}

.car-descr{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.career-subtext{
    /* font-size: 18px; */
    font-size: var(--subtitle-font);
    display: none;
    flex-direction: column;
}

.career-subtext span:nth-child(1){
    margin-bottom: 10px;
}

.career-subtext span:nth-child(n+2){
    text-align: left;
}

.button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .button-link:hover {
    background-color: #0056b3;
  }

/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    
    .career-content{
        margin-top: 300px;
    }
    .career-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .career-descr{
        width: 90%;
    }
    .career-item{
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    .career-subtext{
        /* font-size: 23px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    
    .career-content{
        width: 80%;
        align-items: center;
        margin-top: 300px;
    }
    .career-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .career-descr{
        grid-template-columns: 1fr;
        text-align: center;
        width: 55%;
        gap: 50px;
    }
    .career-item{
        padding: 0;
        justify-content: center;
        /* font-size: 28px; */
        font-size: var(--title-font);
    }
    .career-item p{
        margin: 0 20px;
    }
    .career-subtext{
        /* font-size: 25px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
        text-align: center;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    
    .career-descr{
        width: 90%;
    }
}

/* iPad portrait*/
@media only screen 
/* and (min-device-width: 768px)  */
and (min-device-width: 481px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .career-content{
        width: 80%;
        align-items: center;
        margin-top: 250px;
    }

    .career-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        width: 60%;
        text-align: left;
    }
    
    .career-item{
        justify-content: center;
    }
    .career-item p {
        /* font-size: 22px; */
        font-size: var(--title-font);
        margin: 0 20px;
        text-align: center;
    }
    .career-subtext{
        /* font-size: 20px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
    
    .career-content{
        align-items: center;
        margin-top: 150px;
        gap: 40px;
        width: 70%;
    }

    .career-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }

    .career-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        gap: 15px;
        width: 100%;
    }
    
    .career-item{
        justify-content: center;
        padding: 0;
    }
    .career-item p {
        margin: 0 20px;
        text-align: center;
        /* font-size: 17px; */
        font-size: var(--title-font);
    }
    .career-subtext{
        /* font-size: 15px; */
        font-size: var(--subtitle-font);
        /* margin: 0 20px; */
        text-align: center;
        justify-content: center;
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
    
    .career-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 50px;
    }
    .career-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .career-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 20px;
    }
    .career-item{
        justify-content: center;
        padding: 0;
    }
    .career-item p{
        margin: 0 20px;
        text-align: center;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .career-subtext{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}
