:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.investments{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.investments-content{
    /* margin-top: 200px; */
    margin-top: 230px;
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.investments-title{
    color: white;
    font-size: var(--title-font);
}

.investments-descr{
    display: grid;
    gap: 30px;
    width: 70%;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-rows: auto;
}

.investments-item-container{  
    background-color: white;
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.investments-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    position: relative;
    background: none;
}

.investments-item p{
    width: 90%;
}


.invest-descr{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.investments-subtext{
    /* font-size: 18px; */
    font-size: var(--subtitle-font);
    display: none;
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    
    .investments-content{
        margin-top: 300px;
    }
    .investments-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .investments-descr{
        width: 90%;
    }
    .investments-item{
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    .investments-subtext{
        /* font-size: 22px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    
    .investments-content{
        width: 80%;
        align-items: center;
        margin-top: 300px;
    }
    .investments-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .investments-descr{
        grid-template-columns: 1fr;
        text-align: center;
        width: 65%;
        gap: 50px;
    }
    .investments-item{
        padding: 0;
        justify-content: center;
    }
    .investments-item p{
        /* font-size: 25px; */
        font-size: var(--title-font);
        margin: 0 20px;
    }
    .investments-subtext{
        /* font-size: 22px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
        text-align: center;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    
    .investments-descr{
        width: 90%;
    }
}

/* iPad portrait*/
@media only screen 
/* and (min-device-width: 768px)  */
and (min-device-width: 481px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .investments-content{
        width: 80%;
        align-items: center;
        margin-top: 250px;
    }

    .investments-item-container{
        justify-content: center;
    }

    .investments-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        width: 70%;
        text-align: left;
        grid-template-rows: 1fr 1fr;
    }
    
    .investments-item{
        justify-content: center;
    }
    .investments-item p {
        /* font-size: 20px; */
        font-size: var(--title-font);
        margin: 0 20px;
        text-align: center;
        width: 80%;
    }
    .investments-subtext{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
    
    .investments-content{
        align-items: center;
        margin-top: 150px;
        gap: 40px;
        width: 70%;
    }

    .investments-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }

    .investments-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        gap: 15px;
        width: 100%;
    }
    
    .investments-item{
        display: flex;
        justify-content: center;
    }
    .investments-item p {
        text-align: center;
        /* font-size: 17px; */
        font-size: var(--title-font);
        width: 85%;
    }
    .investments-subtext{
        /* font-size: 15px; */
        font-size: var(--subtitle-font);
        /* margin: 0 20px; */
        text-align: center;
        justify-content: center;
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
    
    .investments-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 50px;
    }
    .investments-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .investments-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 20px;
    }
    .investments-item{
        justify-content: center;
        padding: 0;
    }
    .investments-item p{
        margin: 0 20px;
        text-align: center;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .investments-subtext{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}