:root {
    --nav-bg: white;
    --nav-text: black;
    --nav-active: #FFB001;
}


.navbar{
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 10;
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--nav-bg);
    box-shadow: 0 2px 20px black;
}

.nav-left{
    display: flex;
    align-items: center;
}


.nav-left img{
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 121px;
    height: 38.68px;
}

.nav-right{
    height: 100%;
}

.nav-right ul{
    display: flex;
    gap: 40px;
    height: 100%;
}

.nav-right ul li{
    /* background-color: black; */
    list-style: none;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    /* text-align: center; */
}


.nav-list{
    /* padding: 0 20px; */
    color: var(--nav-text);
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
    /* position: relative; */
    /* display: flex; */
    align-items: center;
    top: 50%;
    /* transform: translateY(-50%); */
    transition: .5s ease;
    cursor: pointer;
}

.nav-list:hover{
    color: var(--nav-active);
}

.nav-list.active{
    color: var(--nav-active);
}

.nav-ext{
    position: absolute;
    width: 160px;
    top: 70px;
    transform: translateX(-47px);
    flex-direction: column;
    display: flex;
    gap: 20px;
    padding: 20px 0;
    background-color: var(--nav-bg);
    border-radius: 0 0 10px 10px;
    z-index: -1;
    box-shadow: gray 0px 2px 1px 1px;
}

.nav-ext p{
    text-align: center;
    color: var(--nav-text);
    text-decoration: none;
    font-size: 16px;
    transition: .5s ease;
    cursor: pointer;
} 

.nav-ext p:hover{
    color: var(--nav-active)
}

.nav-right-toggler{
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;
}

.nav-right-toggler svg{
    width: 2em;
    height: 2em;
    pointer-events: none;
}

.nav-ext-bg{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 8;
    display: none;
}

.nav-toggler-list{
    display: flex;
    position: absolute;
    z-index: 9;
    top: -650px;
    transition: .5s ease;
    width: 100%;
    padding: 70px 30px 30px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: var(--nav-bg);
}

.nav-toggler-list-item{
    text-decoration: none;
    color: var(--nav-text);
    font-size: 18px;
    background-color: white;
    padding: 0 40px;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.nav-toggler-list-item.active{
    color: var(--nav-active);
}

.nav-tog{
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: -10px;
    position: relative;
    top: 0;
    padding: 10px 20px;
    border: 1px solid gray;
    border-radius: 15px;
}


.nav-tog p{
    font-size: 14px;
    text-decoration: none;
    color: var(--nav-text);
    cursor: pointer;
    text-decoration: underline;
}

.nav-tog p.active{
    color: var(--nav-active);
}


@media (max-width: 1200px){
    .nav-right ul{
        gap: 28px;
    }
    .nav-left, .nav-right-toggler{
        transform: scale(.85);
    }
    .nav-left{
        transform-origin: bottom left;
    }
    .nav-right-toggler{
        transform-origin: bottom right;
    }
}

@media (max-width: 1024px){
    .nav-right {
        display: none;
        pointer-events: none;
    }

    .nav-right-toggler{
        display: flex;
    }

}

@media (max-height:500px) and (orientation:landscape){
   
}

@media (max-width:500px){
    .navbar{
        padding: 0 20px;
    }
}
