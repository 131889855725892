:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.aboutus{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutus-content{
    margin-top: 240px;
    margin-bottom: 80px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;                    
}   

.aboutus-title{
    color: white;
    font-size: var(--title-font);
}

.aboutus-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    align-items: center;
}


/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    .aboutus-content{
        margin-top: 300px;
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){

    .aboutus-content{
        width: 80%;
        align-items: center;
        margin-top: 350px;
    }
    .aboutus-item{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){

}

/* iPad portrait*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){
  
    .aboutus-content{
        width: 80%;
        align-items: center;
        margin-top: 300px;
    }
    .aboutus-item{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
}

@media (max-width:767px){
    .aboutus-content{
        margin-top: 300px;
        width: 80%;
        align-items: center;
    }
    .aboutus-item{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
 
    .aboutus-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
    }
    .aboutus-item{
        width: 100%;
        justify-content: center;
        text-align: center;
        padding: 50px 30px;
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){

    .aboutus-content{
        width: 80%;
        align-items: center;
        margin-top: 170px;
    }
    .aboutus-item{
        text-align: center;
    }
}