:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.advertising, .consulting, .finance{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.advertising-content, .consulting-content, .finance-content{
    /* margin-top: 200px; */
    margin-top: 230px;
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.advertising-content{
    margin-top: 170px;
}

.advertising-title, .consulting-title, .finance-title{
    color: white;
    font-size: var(--title-font);
}

.advertising-subtitle{
    background-color: lightgray;
    padding: 15px 20px;
    border-radius: 20px;
    width: 90%;
    /* font-size: 18px; */
    font-size: var(--title-font);
}

.advertising-subtitle span{
    display: flex;
    margin-top: 10px;
    /* font-size: 16px; */
    font-size: var(--subtitle-font);
}

.consulting-descr, .finance-descr{
    display: grid;
    gap: 30px;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
}

.advertising-descr{
    margin: 0 30px;
    display: grid;
    gap: 20px;
    width: 48%;
    grid-template-columns: 1fr;
    margin-top: -30px;
}

.consulting-item-container, .finance-item-container{  
    align-self: flex-start;
    background-color: white;
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.advertising-item-container{
    align-self: flex-start;
    background-color: white;
    color: black;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.consulting-item, .finance-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    position: relative;
    background: none;
}

.advertising-item{
    position: relative;
    background: none;
}

.advertising-item p{
    /* font-size: 16px; */
    font-size: var(--title-font);
    margin: 0 20px;
}

.adv-descr, .consult-descr, .fin-descr{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.consulting-subtext, .finance-subtext{
    /* font-size: 18px; */
    font-size: var(--subtitle-font);
    display: none;
}

.advertising-subtext{
    margin: 0 20px;
    /* font-size: 14px; */
    font-size: var(--subtitle-font);
    display: none;
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    .advertising-content, .consulting-content, .finance-content{
        margin-top: 270px;
    }
    .advertising-content{
        margin-top: 230px;
    }
    .advertising-title, .consulting-title, .finance-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .advertising-subtitle{
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .advertising-subtitle span{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
    .consulting-descr, .finance-descr{
        width: 90%;
    }
    .consulting-item, .finance-item{
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    .consulting-subtext, .finance-subtext{
        /* font-size: 23px; */
        font-size: var(--subtitle-font);
    }
    .advertising-item p{
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .adv-descr{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
    .consulting-subtext, .finance-subtext{
        /* font-size: 20px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    .advertising-content, .consulting-content, .finance-content{
        width: 80%;
        align-items: center;
        margin-top: 300px;
    }
    .advertising-title, .consulting-title, .finance-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .advertising-subtitle{
        text-align: center;
        /* font-size: 27px; */
        font-size: var(--title-font);
        width: 70%;
    }
    .advertising-subtitle span{
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 30px;
        /* font-size: 23px; */
        font-size: var(--subtitle-font);
    }
    .consulting-descr, .finance-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 50px;
    }
    .advertising-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 50%;
        gap: 30px;
        margin-top: 0px;
    }
     .consulting-item, .finance-item{
        justify-content: center;
        /* font-size: 28px; */
        font-size: var(--title-font);
    }

    .advertising-item{
        justify-content: center;
    }
    .advertising-item p {
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    
    .consulting-descr, .finance-descr{
        width: 55%;
        text-align: center;
    }
    .consulting-item p, .finance-item p{
        margin: 0 20px;
    }
    .consulting-subtext, .finance-subtext{
        /* font-size: 25px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
        text-align: center;
    }
    .advertising-subtext{
        /* font-size: 20px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
        text-align: center;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    .consulting-content, .finance-content{
        margin-top: 230px;
    }
    .advertising-content{
        margin-top: 170px;
    }
    .consulting-descr, .finance-descr{
        width: 90%;
    }
    .advertising-subtitle{
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .advertising-subtitle span{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
    .advertising-item p{
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .advertising-subtext{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
    .consulting-item, .finance-item{
        /* font-size: 22px; */
        font-size: var(--title-font);
    }
    .consulting-subtext, .finance-subtext{
        /* font-size: 20px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad portrait*/
@media only screen 
/* and (min-device-width: 768px)  */
and (min-device-width: 481px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .advertising-content, .consulting-content, .finance-content{
        width: 80%;
        align-items: center;
        margin-top: 250px;
    }
    .advertising-content{
        width: 85%;
    }
    .advertising-subtitle{
        text-align: center;
        /* font-size: 20px; */
        font-size: var(--title-font);
        width: 70%;
    }
    .advertising-subtitle span{
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 30px;
        /* font-size: 17px; */
        font-size: var(--subtitle-font);
    }
    .advertising-descr, .consulting-descr, .finance-descr{
        grid-template-columns: 1fr;
        /* grid-template-rows: repeat(2,1fr); */
        justify-content: center;
        text-align: center;
        width: 80%;
    }
    .advertising-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 50%;
        gap: 30px;
        margin-top: 0px;
    }
    .consulting-item, .finance-item{
        justify-content: center;
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    
    .advertising-item{
        justify-content: center;
    }
    .advertising-item p {
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .consulting-descr, .finance-descr{
        width: 60%;
        text-align: left;
    }
    .consulting-item p , .finance-item p{
        margin: 0 20px;
        text-align: center;
    }
    .consulting-subtext, .finance-subtext{
        /* font-size: 23px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
    .advertising-subtext{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
        text-align: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){

    .advertising-content, .consulting-content, .finance-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 30px;
        gap: 40px;
    }
    .advertising-content{
        margin-bottom: 50px;
        gap: 20px
    }
    .consulting-content, .finance-content{
        width: 65%;
    }
    .advertising-title, .consulting-title, .finance-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .advertising-subtitle{
        text-align: center;
        /* font-size: 18px; */
        font-size: var(--title-font);
        width: 90%;
    }
    .advertising-subtitle span{
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 20px;
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
    }
    .advertising-descr, .consulting-descr, .finance-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 100%;
        gap: 15px;
    }
    .advertising-descr{
        width: 70%;
        margin-top: 0px;
    }
    .consulting-item, .finance-item{
        justify-content: center;
    }
    .advertising-item{
        display: flex;
        align-items: center;
    }
    .advertising-item p{
        /* font-size: 17px; */
        font-size: var(--title-font);
        text-align: center;
        width: 90%;
    }
    
    .consulting-descr, .finance-descr{
        text-align: center;
    }

    .consulting-item p , .finance-item p{
        margin: 0 20px;
        text-align: center;
        /* font-size: 17px; */
        font-size: var(--title-font);
    }
    .advertising-subtext, .consulting-subtext, .finance-subtext{
        /* font-size: 15px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }

}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){

    .advertising-content, .consulting-content, .finance-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 50px;
    }
    .advertising-title, .consulting-title, .finance-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
     .consulting-descr, .finance-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 20px;
    }
     .consulting-item, .finance-item{
        justify-content: center;
        padding: 15px;
    }
    .consulting-descr, .finance-descr{
        text-align: center;
    }
    .advertising-descr{
        width: 50%;
        justify-content: center;
        text-align: center;
    }

    .consulting-item, .finance-item{
        padding: 0;
    }
    .advertising-item p, .consulting-item p , .finance-item p{
        margin: 0 20px;
        text-align: center;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .advertising-subtext,.consulting-subtext, .finance-subtext{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}