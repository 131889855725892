*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
}

body{
  background: #000000;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

