:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.simts, .consumerScience, .b2bScience, .publications{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
    /* margin-top: 200px; */
    margin-top: 200px;
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.consumerScience-content, .b2bScience-content{
    margin-top: 170px;
}

.publications-content{
    margin-top: 170px;
    margin-bottom: 100px;
}

/* .b2bScience-content{
    margin-top: 150px;
    margin-bottom: 100px;
} */

.simts-title, .consumerScience-title, .b2bScience-title, .publications-title{
    color: white;
    font-size: var(--title-font);
}

.simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
    display: grid;
    gap: 30px;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
}
.consumerScience-descr, .b2bScience-descr{
    gap: 15px;
}

.simts-descr{
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3,1fr);
}

.publications-descr{
    grid-template-columns: 1fr;
    justify-content: center;
    width: 80%;
    place-self: center;
}

.simts-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
}

.consumerSc-item-container, .b2bSc-item-container{  
    align-self: flex-start;
    background-color: white;
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.consumerScience-item, .b2bScience-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    position: relative;
    background: none;
}

.conSc-descr, .b2bSc-descr{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.conSc-subtext, .b2bSc-subtext{
    /* font-size: 18px; */
    font-size: var(--subtitle-font);
    display: none;
}

.publications-item{
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap:20px;
}

.publications-item-container{
    display: flex;
    gap: 30px;
    align-items: center;
}

.publications-item-title{
    font-weight: bolder;
    /* font-size: 20px; */
    font-size: var(--title-font);
}

.publications-item-subtitle{
    color: gray;
    /* font-size: 18px; */
    font-size: var(--subtitle-font);
}

.expand{
    margin-left: auto;
    padding: 5px 12px;
    color: black;
    background-color: #bbb;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: .5s ease;
}

.publications-item-descr{
    display: none;
    /* font-size: 16px; */
    font-size: var(--subtitle-font);
    flex-direction: column;
}

.publications-item.active .publications-item-descr{
    display:  flex;
}


.publications-item.active .expand{
    background-color: #FFB001;
    color: black;
}

.viewPdf{
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #FFB001;
    border: 1px solid black;
    border-radius: 8px;
    color: black;
    cursor: pointer;
    width: 100px;
    text-align: center;
}

.scSubtext{
    color: black;
    font-size: var(--title-font);
    /* background-color: white; */
    background-color: lightgray;
    padding: 15px 20px;
    border-radius: 20px;
    margin-bottom: -30px;
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    .simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
        margin-top: 270px;
    }
    .publications-content{
        margin-top: 230px;
    }
    .simts-title, .consumerScience-title, .b2bScience-title, .publications-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
        width: 90%;
    }
    .simts-item, .consumerScience-item, .b2bScience-item, .publications-item{
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    .publications-item{
        padding: 30px;
    }
    .publications-item-container{
        gap: 20px;
    }
    
    .publications-item-title{
        font-weight: bolder;
        /* font-size: 22px; */
        font-size: var(--title-font);
    }
    .publications-item-subtitle{
        color: gray;
        /* font-size: 10px; */
        font-size: var(--subtitle-font);
    }
    .expand{
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .consumerScience-item p, .b2bScience-item p{
        width: 92%;
    }
    .conSc-subtext, .b2bSc-subtext{
        /* font-size: 22px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    .simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
        width: 80%;
        align-items: center;
        margin-top: 300px;
    }
    .simts-title, .consumerScience-title, .b2bScience-title, .publications-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .simts-title{
        text-align: center;
    }
    .scSubtext{
        text-align: center;
        margin-bottom: -20px;
        width: 70%;
    }
    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2,1fr);
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 50px;
    }
    .simts-descr{
        margin-top: 40px;
    }
    .publications-descr{
        width: 90%;
        grid-template-rows: auto;
        text-align: left;
    }
    .simts-item, .consumerScience-item, .b2bScience-item, .publications-item{
        justify-content: center;
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    .publications-item{
        padding: 30px;
    }
    .publications-item-container{
        gap: 20px;
    }
    
    .publications-item-title{
        font-weight: bolder;
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .publications-item-subtitle{
        color: gray;
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
    .expand{
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .consumerScience-descr, .b2bScience-descr{
        width: 70%;
        gap: 30px;
        grid-template-rows: auto;
    }
    .consumerScience-item p, .b2bScience-item p {
        margin: 0 20px;
    }
    .conSc-subtext, .b2bSc-subtext{
        /* font-size: 22px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){

    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
        width: 90%;
    }
    .expand{
        /* font-size: 14px; */
        font-size: var(--title-font);
    }
}

/* iPad portrait*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
        width: 80%;
        align-items: center;
        margin-top: 200px;
    }
    .simts-content, .publications-content{
        margin-top: 250px;
    }
    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2,1fr);
        justify-content: center;
        text-align: center;
        width: 80%;
    }
    .simts-descr{
        margin-top: 40px;
    }
    .publications-descr{
        width: 90%;
        grid-template-rows: auto;
        text-align: left;
    }
    .scSubtext{
        text-align: center;
        margin-bottom: -20px;
        width: 70%;
    }
    .simts-title{
        text-align: center;
    }
    .simts-item, .consumerScience-item, .b2bScience-item, .publications-item{
        justify-content: center;
    }
    .publications-item{
        padding: 30px;
    }
    .publications-item-container{
        gap: 20px;
    }
    
    .publications-item-title{
        font-weight: bolder;
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .publications-item-subtitle{
        color: gray;
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
    .expand{
        /* font-size: 17px; */
        font-size: var(--title-font);
    }
    .consumerScience-descr, .b2bScience-descr{
        width: 70%;
        gap: 30px;
        grid-template-rows: auto;
    }
    .consumerScience-item p, .b2bScience-item p {
        margin: 0 20px;
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .conSc-subtext, .b2bSc-subtext{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
        margin: 5px 20px;
        justify-content: center;
    }

    .publications-item-descr{
        align-items: center;
    }

}


@media (max-device-width: 767px) and (min-device-width: 481px){
    .simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
        margin-top: 200px;
        width: 80%;
        align-items: center;
    }
    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
    }
    .publications-descr{
        width: 90%;
        grid-template-rows: auto;
        text-align: left;
    }
    .scSubtext{
        text-align: center;
        margin-bottom: -20px;
    }
    .simts-item, .consumerScience-item, .b2bScience-item, .publications-item{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
    .publications-item{
        position: relative;
        padding: 20px;
    }
    .publications-item-container{
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .publications-item-descr{
        text-align: left;
    }
    .expand{
        position: absolute;
        right: 20px;
        top: 35px;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){

    .simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
        width: 80%;
        align-items: center;
        /* margin-top: 150px;
        margin-bottom: 50px; */
        margin-top: 130px;
        margin-bottom: 50px;
        gap: 40px;
    }

    /* .simts-content{
        margin-top: 170px;
    } */
    .simts-content{
        margin-top: 150px;
    }
    
    .consumerScience-title, .b2bScience-title, .publications-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .simts-title{
        /* font-size: 25px; */
        font-size: var(--title-font);
        text-align: center;
    }
    .scSubtext{
        text-align: center;
        margin-bottom: -20px;
    }
    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2,1fr);
        justify-content: center;
        text-align: center;
        width: 100%;
        gap: 20px;
    }
    .simts-descr{
        grid-template-rows: auto;
    }
    .publications-descr{
        width: 90%;
        grid-template-rows: auto;
    }
    .simts-item, .consumerScience-item, .b2bScience-item, .publications-item{
        justify-content: center;
        padding: 15px;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .publications-item{
        padding: 20px;
    }
    .publications-item-container{
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .publications-item-title{
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .publications-item-subtitle{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
    }
    .expand{
        /* font-size: 12px; */
        font-size: var(--title-font);
    }
    .publications-item-descr{
        /* font-size: 14px; */
        font-size: var(--subtitle-font);
        text-align: left;
    }
    .consumerScience-descr, .b2bScience-descr{
        text-align: center;
        grid-template-rows: auto;
    }
    .consumerScience-item, .b2bScience-item{
        padding: 0;
    }
    .consumerScience-item p , .b2bScience-item p{
        margin: 0 20px;
        text-align: center;
        /* font-size: 16px; */
        font-size: var(--title-font);
    }
    .conSc-subtext, .b2bSc-subtext{
        /* font-size: 14px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}


/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
    .scSubtext{
        text-align: center;
    }
    .simts-content, .consumerScience-content, .b2bScience-content, .publications-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 50px;
    }
    .simts-title, .consumerScience-title, .b2bScience-title, .publications-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .simts-title{
        text-align: center;
    }
    .simts-descr, .consumerScience-descr, .b2bScience-descr, .publications-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 20px;
    }
    .publications-descr{
        width: 90%;
        grid-template-rows: auto;
    }
    .simts-item, .consumerScience-item, .b2bScience-item, .publications-item{
        justify-content: center;
        padding: 15px;
    }
    .publications-item{
        padding: 30px;
    }
    .publications-item-container{
        gap: 20px;
    }
    .publications-item-title{
        font-weight: bolder;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .publications-item-subtitle{
        color: gray;
        /* font-size: 14px; */
        font-size: var(--subtitle-font);
    }
    .expand{
        /* font-size: 12px; */
        font-size: var(--title-font);
    }
    .publications-item-descr{
        /* font-size: 14px; */
        font-size: var(--subtitle-font);
        text-align: left;
    }
    .consumerScience-descr, .b2bScience-descr{
        text-align: center;
        grid-template-rows: auto;
    }
    .consumerScience-item, .b2bScience-item{
        padding: 0;
    }
    .consumerScience-item p , .b2bScience-item p{
        margin: 0 30px;
        text-align: center;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .conSc-subtext, .b2bSc-subtext{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
    
}