:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.training{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.training-content{
    margin-top: 170px;
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.training-title{
    color: white;
    font-size: var(--title-font);
}

.training-descr{
    display: grid;
    gap: 15px;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
}

.training-item-container{  
    align-self: flex-start;
    background-color: white;
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.training-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    position: relative;
    background: none;
}

.train-descr{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.training-subtext{
    /* font-size: 18px; */
    font-size: var(--subtitle-font);
    display: none;
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    
    .training-content{
        margin-top: 270px;
    }
    .training-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .training-descr{
        width: 90%;
    }
    .training-item{
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
    .training-subtext{
        /* font-size: 23px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    
    .training-content{
        width: 80%;
        align-items: center;
        margin-top: 250px;
    }
    .training-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .training-descr{
        grid-template-columns: 1fr;
        text-align: center;
        width: 70%;
        gap: 30px;
    }
    .training-item{
        padding: 0;
        justify-content: center;
        /* font-size: 28px; */
        font-size: var(--title-font);
    }
    .training-item p{
        margin: 0 20px;
    }
    .training-subtext{
        /* font-size: 25px; */
        font-size: var(--subtitle-font);
        margin: 10px 20px;
        justify-content: center;
        text-align: center;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    
    .training-descr{
        width: 90%;
    }
}

/* iPad portrait*/
@media only screen 
/* and (min-device-width: 768px)  */
and (min-device-width: 481px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .training-content{
        width: 80%;
        align-items: center;
        margin-top: 170px;
    }

    .training-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        width: 70%;
        gap: 30px;
        text-align: left;
    }
    
    .training-item{
        justify-content: center;
    }
    .training-item p {
        /* font-size: 22px; */
        font-size: var(--title-font);
        margin: 0 20px;
        text-align: center;
    }
    .training-subtext{
        /* font-size: 20px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
    
    .training-content{
        align-items: center;
        margin-top: 150px;
        gap: 40px;
        width: 80%;
    }

    .training-title{
        /* font-size: 30px; */
        font-size:var(--title-font);
    }

    .training-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        gap: 20px;
        width: 100%;
    }
    
    .training-item{
        justify-content: center;
        padding: 0;
    }
    .training-item p {
        margin: 0 20px;
        text-align: center;
        /* font-size: 17px; */
        font-size: var(--title-font);
    }
    .training-subtext{
        /* font-size: 15px; */
        font-size: var(--subtitle-font);
        /* margin: 0 20px; */
        text-align: center;
        justify-content: center;
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
    
    .training-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 50px;
    }
    .training-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .training-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 20px;
    }
    .training-item{
        justify-content: center;
        padding: 0;
    }
    .training-item p{
        margin: 0 20px;
        text-align: center;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .training-subtext{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
        margin: 0 20px;
        text-align: center;
        justify-content: center;
    }
}