:root{
    --title-font: 16px;
    /* --subtitle-font: 14px; */
}

.mission{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mission-content{
    /* margin-top: 200px; */
    margin-top: 230px;
    margin-bottom: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
}

.mission-title{
    color: white;
    font-size: var(--title-font);
}

.mission-descr{
    display: grid;
    gap: 30px;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
}

.mission-item{
    /* font-size: 20px; */
    font-size: var(--title-font);
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    .mission-content{
        margin-top: 300px;
    }
    .mission-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .mission-descr{
        width: 90%;
    }
    .mission-item{
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    .mission-content{
        width: 80%;
        align-items: center;
        margin-top: 300px;
    }
    .mission-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .mission-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 50px;
    }
    .mission-item{
        justify-content: center;
        /* font-size: 25px; */
        font-size: var(--title-font);
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    .mission-descr{
        width: 90%;
    }
}

/* iPad portrait*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){
    .mission-content{
        width: 80%;
        align-items: center;
        margin-top: 250px;
    }
    .mission-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
    }
    .mission-item{
        justify-content: center;
    }
}

@media (max-width:767px){
    .mission-content{
        margin-top: 300px;
        width: 80%;
        align-items: center;
    }
    .mission-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
    }
    .mission-item{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){

    .mission-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        gap: 40px;
    }
    .mission-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .mission-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 100%;
        gap: 20px;
    }
    .mission-item{
        justify-content: center;
        padding: 10px;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
    .mission-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
        margin-bottom: 50px;
    }
    .mission-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .mission-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
        gap: 20px;
    }
    .mission-item{
        justify-content: center;
        padding: 10px;
    }
}