.footer{
    width: 100%;
    height: 30px;
    position: fixed;
    bottom: 0;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid black;
}

.footer p{
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
}

.footer p span{
    display: flex;
}