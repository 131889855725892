:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.products{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-content{
    /* margin-top: 150px; */
    margin-top: 200px;
    margin-bottom: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
    padding-bottom: 50px;
}

.products-title{
    color: white;
    /* font-size: 32px; */
    font-size: var(--title-font);
}

.products-descr{
    display: grid;
    gap: 30px;
    width: 88%;
    grid-template-columns: repeat(2, 1fr);
}

.products-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    padding: 5px 10px;
    gap: 10px;
    color: black;
    align-self: flex-start;
}

.products-item{
    align-items: center;
    height: 60px;
    /* font-size: 20px; */
    font-size: var(--title-font);
    gap: 20px;
    display: flex;
    position: relative;
}

.prod-img-container{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prod-img{
    width: auto;
    height: 90%;
}

.prod-name{
    width: 55%;
    /* font-size: 18px; */
    font-size: var(--title-font);
}

.prod-descr{
    display: flex;
    align-items: center;
    position: absolute;
    padding: 10px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.showDescr{
    display: none;
    text-align: left;
    padding: 10px 20px;
    font-size: var(--subtitle-font);
}



/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    .products-content{
        margin-top: 300px;
    }
    .products-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .products-descr{
        width: 90%;
    }
    .prod-name{
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .showDescr{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    .products-content{
        width: 80%;
        align-items: center;
        margin-top: 220px;
    }
    .products-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .products-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 60%;
        gap: 30px;
    }
    .products-item{
        height: 90px;
    }
    .prod-img-container{
        width: 120px;
        margin-left: 30px;
    }
    .prod-name{
        /* font-size: 22px; */
        font-size: var(--title-font);
        text-align: left;
        /* background-color: red; */
    }
    .showDescr{
        /* font-size: 20px; */
        font-size: var(--subtitle-font);
        padding: 10px 40px;
    }

}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    .products-content{
        margin-top: 200px;
    }
    .products-descr{
        width: 90%;
    }
    .products-item{
        height: 60px;
    }
    .prod-img-container{
        width: 60px;
        margin-left: 30px;
    }
    .prod-name{
        text-align: left;
        /* font-size: 18px; */
        font-size: var(--title-font);
    }
    .showDescr{
        /* font-size: 16px; */
        font-size: var(--subtitle-font);
    }
}

/* iPad portrait*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .products-content{
        width: 80%;
        align-items: center;
        margin-top: 200px;
    }
    .products-title {
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .products-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 60%;
        gap: 30px;
    }
    .products-item{
        height: 70px;
        padding-right: 40px;
    }
    .prod-img-container{
        width: 100px;
        margin-left: 20px;
    }
    .prod-name{
        /* font-size: 20px; */
        font-size: var(--title-font);
        text-align: left;
    }
    .showDescr{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
        padding: 10px 30px;
    }
}

@media (max-width:767px){
    .products-content{
        margin-top: 250px;
        width: 80%;
        align-items: center;
    }
    .products-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        width: 80%;
    }
    .products-item{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
    .prod-name{
        /* font-size: 20px; */
        font-size: var(--title-font);
    }
    .showDescr{
        /* font-size: 18px; */
        font-size: var(--subtitle-font);
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
    .products-content{
        width: 90%;
        align-items: center;
        margin-top: 150px;
    }

    .products-descr{
        gap: 20px;
    }

    .products-item{
        height: 50px;
        gap: 10px;
        gap: 5px;
    }
    
    .prod-img-container{
        height: 100%;
        margin-left: -20px;
    }

    .prod-img{
        height: 100%;
    }

    .prod-name{
        text-align: left;
        /* font-size: 15px; */
        font-size: var(--title-font);
        width: 50%;
    }
    .showDescr{
        /* font-size: 13px; */
        font-size: var(--subtitle-font);
        padding: 10px 20px;
    }
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
    .products-content{
        width: 80%;
        align-items: center;
        margin-top: 150px;
    }
    .products-title{
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .products-descr{
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: left;
        width: 60%;
        gap: 20px;
    }
    .products-item{
        justify-content: center;
        padding: 0;
    }
    .prod-img-container{
        justify-content: center;
        width: 60px;
        margin-left: -30px;
    }
    .prod-name{
        text-align: left;
        /* font-size: 15px; */
        font-size: var(--title-font);
    }
    .prod-descr{
        /* font-size: 13px; */
        font-size: var(--subtitle-font);
    }
}