:root{
    --title-font: 16px;
    --subtitle-font: 14px;
}

.contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-content{
    margin-top: 150px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.contact-title{
    width: 35%;
    color: white;
    font-size: var(--title-font);
}


.contact-form{
    width: 100%;
    background-color: white;
    height: 100%;
    padding: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cols{
    display: flex;
    gap: 40px;
}
.col1{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 35%;
}
.col2{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 65%;
}

.form-control{
    width: 100%;
    display: flex;
    min-height: 30px;
    gap: 20px;
}

.form-control label{
    width: 120px;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    /* font-size: 18px; */
    font-size: var(--title-font);
    white-space: nowrap;
    position: relative;
    padding-top: 5px;
}

.col2 .form-control label{
    white-space: unset;
}

.form-control label span{
    color: rgb(160, 0, 0);
    position: absolute;
    left: 100%;
    top: 0;
}

.form-control input{
    padding: 5px 10px;
    width: 90%;
    background-color: #eee;
    border: 1px solid gray;
    font-size: var(--subtitle-font);
}

.form-control textarea{
    background-color: #eee;
    border: 1px solid gray;
    padding: 5px 10px;
    width: 90%;
    height: 100px;
    font-size: var(--subtitle-font);
}

.form-buttons{
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 50px;
    /* background-color: red; */
}

.form-buttons button{
    color:white;
    padding: 8px;
    width: 100px;
    border-radius: 8px;
    /* font-size: 16px; */
    font-size: var(--title-font);
    border: none;
    outline: none;
    cursor: pointer;
}

.form-buttons button[type='submit']{
    background-color: #007BFF;
}
.form-buttons button[type='button']{
    background-color: #8A8A8A;
}

.messageDisp{
    width: 250px;
    height: 100px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 2px solid black;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: none;
    
}


/* iPad Pro landscape*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2){
    .contact-content{
        margin-top: 250px;
    }
    .contact-title{
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .col2 .form-control label{
        white-space: unset;
    }
   
}

/* iPad Pro portrait*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2){
    .contact-content{
        width: 60%;
        align-items: center;
        margin-top: 250px;
        flex-direction: column;
        gap: 50px;
    }
    .contact-title {
        padding: 0;
        text-align: center;
        /* font-size: 40px; */
        font-size: var(--title-font);
    }
    .contact-form{
        transform: scale(1.2);
        transform-origin: top;
    }
    .cols{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .cols  > div{
        width: 100%;
    }
    .cols .form-control label{
        width: 250px;
    }
    .col2 .form-control label{
        text-align: right;
    }
}

/* iPad landscape*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2){
    .contact-content{
        margin-top: 150px;
    }
    
}

/* iPad portrait*/
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2){

    .contact-content{
        align-items: center;
        margin-top: 220px;
        flex-direction: column;
        gap: 50px;
    }
    .cols{
        flex-direction:  column;
    }
    .contact-title {
        padding: 0;
        text-align: center;
        /* font-size: 35px; */
        font-size: var(--title-font);
    }
    .contact-form{
        width: 90%;
    }
    .cols{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .cols  > div{
        width: 100%;
    }
    .cols .form-control label{
        width: 250px;
    }
    .col2 .form-control label{
        text-align: right;
    }
}

@media (max-width:767px){
    .contact-content{
        align-items: center;
        margin-top: 200px;
        flex-direction: column;
        gap: 30px;
        width: 80%;
    }
    .contact-title {
        width: 100%;
        padding: 0;
        text-align: center;
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .contact-form{
        width: 95%;
        padding: 30px;
    }
    .cols{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .cols  > div{
        width: 100%;
    }
    .col2 .form-control label{
        text-align: left;
    }
}

/* iPhone portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: portrait){
 
    .contact-content{
        align-items: center;
        margin-top: 150px;
        margin-bottom: 150px;
        flex-direction: column;
        gap: 30px;
        width: 90%;
    }
    .contact-title {
        width: 100%;
        padding: 0;
        text-align: center;
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .contact-form{
        width: 95%;
        padding: 30px;
    }

    .form-control{
        flex-direction: column;
        gap: 0;
        width: 100%;
    }

    .form-control label{
        justify-content: left;
        /* font-size: 16px; */
        font-size: var(--title-font);
        width: fit-content;
    }

    .form-control input, .form-control textarea{
        width: 100%;
        min-height: 35px;
    }

    .form-buttons{
        width: 100%;
        justify-content: center;
        gap: 30px;
    }

    .form-buttons button{
        transform: scale(1.1);
    }
    .cols{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .cols  > div{
        width: 100%;
    }
    .col2 .form-control label{
        text-align: left;
    }
    
}

/* iPhone landscape  */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (orientation: landscape){
  
    .contact-content{
        align-items: center;
        margin-top: 150px;
        margin-bottom: 150px;
        flex-direction: column;
        gap: 30px;
        width: 90%;
    }
    .contact-title {
        width: 100%;
        padding: 0;
        text-align: center;
        /* font-size: 30px; */
        font-size: var(--title-font);
    }
    .contact-form{
        width: 80%;
        padding: 30px;
    }

    .form-control{
        flex-direction: column;
        gap: 0;
        width: 100%;
    }

    .form-control label{
        justify-content: left;
        /* font-size: 16px; */
        font-size: var(--title-font);
        width: fit-content;
    }

    .form-control input, .form-control textarea{
        width: 100%;
        min-height: 35px;
    }

    .form-buttons{
        width: 100%;
        justify-content: center;
        gap: 30px;
    }

    .form-buttons button{
        transform: scale(1.1);
    }
    
}